<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Редактирование акции</div>
                <div class="page__desc">Измените данные акции</div>
            </div>
        </div>

        <div class="page__content">
            <form v-if="promotion !== null" class="form-group" method="POST" action="" @submit.prevent="update()">

                <label for="title">Название</label>
                <div class="control-group">
                    <input
                            v-model="promotion.data.title"
                            id="title"
                            type="text"
                            name="title"
                            class="form-control"
                            :readonly="promotion.data.is_weekly === 1"
                            required>
                </div>
                <br>

                <label for="discount">Скидка (%)</label>
                <div class="control-group">
                    <input
                            v-model="promotion.data.discount"
                            id="discount" 
                            type="text" 
                            name="discount" 
                            class="form-control"
                            placeholder="15" 
                            required>
                </div>
                <br>

                <label for="time_from">Период</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="time_from"
                                    id="time_from"
                                    type="datetime-local"
                                    name="time_from"
                                    class="form-control"
                                    :readonly="promotion.data.is_weekly === 1"
                                    required>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="control-group">
                            <input
                                    v-model="time_to"
                                    id="time_to"
                                    type="datetime-local"
                                    name="time_to"
                                    class="form-control"
                                    :readonly="promotion.data.is_weekly === 1"
                                    required>
                        </div>
                    </div>
                </div>
                <br>

                <label for="photo">Фото</label><br>
                <img v-if="promotion.data.photo !== null" class="img-thumbnail" :src="promotion.data.photo">
                <div class="control-group">
                    <input ref="photo" id="photo" type="file" name="photo" class="form-control">
                </div>
                <br>

                <label for="product_ids">Продукты</label>
                <div class="control-group">
                    <select
                            v-model="promotion.product_ids"
                            id="product_ids"
                            class="form-control"
                            style="height: 200px"
                            multiple required>

                        <template
                                v-for="cc in categories"
                                :label="cc['title']">

                            <optgroup
                                    v-bind:key="cc['id']"
                                    :label="cc['title']"></optgroup>

                            <optgroup
                                    v-for="c in cc['categories']"
                                    v-bind:key="c['id']"
                                    :label="'↳' + c['title']">

                                <option
                                        v-for="p in c['products']"
                                        v-bind:key="p['id']"
                                        :value="p['id']">{{ p['title'] }}</option>
                            </optgroup>
                        </template>
                    </select>
                </div>
                <br>

                <label v-if="promotion.data.is_weekly === 0" for="is_visible"><input v-model="promotion.data.is_visible" type="checkbox" id="is_visible" name="is_visible"> Показывать на главной?</label>
                <br><br>

                <label v-if="promotion.data.is_weekly === 0" for="is_carousel"><input v-model="promotion.data.is_carousel" type="checkbox" id="is_carousel" name="is_carousel"> Отдельная карусель с товарами?</label>
                <br><br>

                <input type="submit" class="btn btn-success btn-large" value="Сохранить">

            </form>
        </div>

    </div>
</template>


<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'CatalogPromotionUpdate',
        computed: {
            categories() {
                return this.$store.state.promotions.promotionsProducts
            },
            promotion() {
                return this.$store.state.promotions.promotion;
            },
            time_from: {
                get() {
                    return this.$moment(this.$store.state.promotions.promotion.data.time_from * 1000).format('YYYY-MM-DDTHH:mm')
                },
                set(newVal) {
                    this.$store.state.promotions.promotion.data.time_from = this.$moment(newVal).unix()
                }
            },
            time_to: {
                get() {
                    return this.$moment(this.$store.state.promotions.promotion.data.time_to * 1000).format('YYYY-MM-DDTHH:mm')
                },
                set(newVal) {
                    this.$store.state.promotions.promotion.data.time_to = this.$moment(newVal).unix()
                }
            }
        },
        methods: {
            ...mapActions([
                'catalogPromotionsGet',
                'catalogPromotionsUpdate',
                'catalogPromotionsProducts',
            ]),
            async getPromotion() {
                await this.catalogPromotionsGet({
                    id: this.$route.params.id,
                })
            },
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async update() {
                const formData = new FormData();
                formData.append("photo", this.$refs.photo.files[0]);
                formData.append("id", this.promotion.data.id);
                formData.append("title", this.promotion.data.title);
                formData.append("discount", this.promotion.data.discount);
                formData.append("is_visible", this.promotion.data.is_visible);
                formData.append("is_carousel", this.promotion.data.is_carousel);
                formData.append("time_from", this.promotion.data.time_from);
                formData.append("time_to", this.promotion.data.time_to);
                formData.append("product_ids", this.promotion.product_ids);

                await this.catalogPromotionsUpdate(formData)
            }
        },
        created() {
            this.getPromotion();
            this.getProducts()
        },
    }
</script>